<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    if (
      this.locationEventManager &&
      this.locationEventManager?.channels?.locationChanged
    ) {
      this.locationEventManager.listener.on(
        this.locationEventManager.channels.locationChanged,
        (data) => {
          this.setLocation(data.data);
        }
      );
    }
  },
  computed: {
    ...mapGetters({
      modules: "availableModules",
    }),
    locationEventManager() {
      return this.modules["location"]?.eventManager?.location;
    },
  },
  methods: {
    ...mapActions({
      setLocation: "home/setLocation",
    }),
  },
};
</script>
